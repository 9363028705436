import { query, redirect } from '@solidjs/router';
import { NotFoundError } from '~/errors/notFoundError';
import { companyGateway } from '~/gateways/companyInfoGateway';
import { presentCompanyInfo, fallbackInfo } from '~/presenters/CompanyStylePresenter';
import { getCurrentDomain } from '~/utils/domain';

export const queryCompanyInfo = query(async () => {
  try {
    const companyInfo = await companyGateway.getCompanyInfo(getCurrentDomain());
    return Object.assign({}, fallbackInfo, presentCompanyInfo(companyInfo));
  } catch (error) {
    console.log('Error loading company info:', error);
    if (error instanceof NotFoundError) {
      throw redirect('/company-not-found');
    }

    return fallbackInfo;
  }
}, 'companyInfo');
