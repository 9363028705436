import { Style } from '@solidjs/meta';
import { AccessorWithLatest, createAsync } from '@solidjs/router';
import { Component, JSX, createContext, useContext } from 'solid-js';
import { PresentableCompayStyle } from '~/presenters/CompanyStylePresenter';
import { queryCompanyInfo } from '~/queries/companyInfo';
interface CompanyStylingType {
  companyStyling?: AccessorWithLatest<PresentableCompayStyle | undefined>;
}

const CompanyStylingContext = createContext<CompanyStylingType>();

interface CompanyStylingProps {
  children: JSX.Element;
}

export const CompanyStyling: Component<CompanyStylingProps> = (props) => {
  const companyStyling = createAsync(() => queryCompanyInfo());

  return (
    <>
      <Style>
        {`
        :root {
          --primary-color: ${companyStyling()?.primaryColor};
          --hover-color: ${companyStyling()?.hoverColor};
          --active-color: ${companyStyling()?.activeColor};
          --gradient-color-start: ${companyStyling()?.gradientStartColor};
          --gradient-color-end: ${companyStyling()?.gradientEndColor};
        }
      `}
      </Style>
      <CompanyStylingContext.Provider value={{ companyStyling }}>{props.children}</CompanyStylingContext.Provider>
    </>
  );
};

export function useStyling() {
  const context = useContext(CompanyStylingContext);
  if (!context) {
    throw new Error('useStyling must be used within an CompanyStylingContext');
  }
  return context;
}
